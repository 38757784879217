import * as React from 'react';

export default {
    seo: {
        title: 'Kontakt',
        description: 'Rozsáhlá internetová vysokorychlostní síť na míru s rychlostí až 1000mbps se skvělou podporou, neustálým servisem, chytrou televizí s možností záznamu až dvou týdnů.'
    },
    sections: {
        hero: {
            heading: 'Kontaktujte nás',
        },
    },
};
