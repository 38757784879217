import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import * as React from 'react';
import { useForm } from 'react-hook-form';
import styled from 'styled-components';
import CompanyData from '../../content/general/company';
import FormData from '../../content/general/form';
import KontaktData from '../../content/kontakt';
import { Card } from '../components/card';
import _Form, { Form, Input } from '../components/form';
import { Hero } from '../components/sections/general/hero/hero.style';
import Seo from '../components/sections/general/seo';
import Layout from '../layouts/staticLayout';
import { colors, fonts } from '../styles/constants';

const { seo, sections } = KontaktData;

const ContactInfo = styled.article`
    text-align: left;
    article {
        ${Card};
        padding: 1rem;
        background-color: ${colors.clr_g};
        & + article {
            margin-top: 0.5em;
        }
    }
`;
const HeroCustom = styled(Hero)`
    & > div {
        align-content: center;
        background-color: transparent;
        display: grid;
        grid-template-areas: 'heading' 'form' 'info';
        padding-top: 6.5rem;
        justify-content: center;
        grid-gap: 2rem;
        align-items: center;
        @media (min-width: 810px) {
            grid-template-areas: 'heading info-heading' 'form info';
            height: 100vh;
        }
        & > div {
            margin: unset;
            grid-area: form;
            min-width: 350px;
            .contact_button {
                all: unset;
                background-color: ${colors.clr_b};
                padding: 0.25em 2rem;
                font-size: 1rem;
                border-radius: 0.25em;
                margin-left: auto;
                margin-right: auto;
                cursor: pointer;
                box-shadow: 0 4px 5px rgba(0, 0, 0, 0.25);
            }
        }
        ${ContactInfo} {
            grid-area: info;
            align-self: flex-start;
        }
        h1 {
            text-align: center;
            grid-area: heading;
            font-size: ${fonts.fs__h2};
        }
    }
`;

//TODO: fix warning if input is not filled
export default () => {
    gsap.registerPlugin(ScrollTrigger);

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            name: '',
            email: '',
            question: '',
        },
    });

    return (
        <Layout>
            <Seo {...seo} />

            <HeroCustom>
                <div>
                    <h1>{sections.hero.heading}</h1>
                    <_Form />
                    {/* <form
                        onSubmit={handleSubmit((data) => {
                            alert(JSON.stringify(data));
                        })}
                        className="contact_form"
                    >
                        <label hidden htmlFor="name">
                            {FormData.name}
                        </label>
                        <input
                            {...register('name', { required: true })}
                            className="contact_input"
                            id="name"
                            name="name"
                            type="text"
                            placeholder={FormData.name}
                        />
                        {errors.name && <span>{FormData.required}</span>}
                        <label hidden htmlFor="email">
                            {FormData.email}
                        </label>
                        <input
                            className="contact_input"
                            {...register('email', { required: true })}
                            type="email"
                            name="email"
                            id="email"
                            placeholder={FormData.email}
                        />
                        {errors.email && <span>{FormData.required}</span>}
                        <label hidden htmlFor="question">
                            {FormData.question}
                        </label>
                        <textarea
                            className="contact_input"
                            {...register('question', { required: true })}
                            name="question"
                            id="question"
                            rows={10}
                            placeholder={FormData.question}
                        />
                        {errors.question && <span>{FormData.required}</span>}
                        <p className="contact_gdpr">{FormData.gdpr}</p>
                        <button className="contact_button" type="submit">
                            {FormData.button}
                        </button>
                    </form> */}

                    <ContactInfo>
                        <article>
                            <address>{CompanyData.address}</address>
                            <p>{CompanyData.open}</p>
                        </article>
                        <article>
                            <p>{CompanyData.email}</p>
                            <p>{CompanyData.tel}</p>
                        </article>
                        <article>
                            {CompanyData.bank.map(({ name, accountno, code }, key) => (
                                <React.Fragment key={key + name}>
                                    <p>
                                        <strong>{name}</strong>
                                    </p>
                                    <p>
                                        {accountno}/{code}
                                    </p>
                                </React.Fragment>
                            ))}
                        </article>
                    </ContactInfo>
                </div>
            </HeroCustom>
        </Layout>
    );
};
